<template>
    <div class="mypage-modal-wrap">
        <div class="top_wrap">
            <div class="modal-title"></div>
            <div class="close_btn_wrap request_modal">
                <button class="close_modal" @click="modalClose()"></button>
            </div>
        </div>
        <div class="middle_wrap">
            '
            <div class='subject'>{{ itemData.i_name }}</div>
            ' <br/>
            <div class="content" v-html="$t('mypage_accept_confirm') +'</br>'+ $t('mypage_accept_confirm3')"></div>
            <div class="confirm_btn_wrap">
                <button class="btn save" @click="updateItem">{{ $t('yes') }}</button>
                <button class="btn cancel" @click="modalClose()">{{ $t('cancel') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import itemValidator from "@/mixins/validators/itemValidator";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";

export default {
    name: "ItemWritePremiumMarketAddLayout",
    mixins: [itemValidator],
    components: {},
    inject: ['itemWriteSetData'],
    provide() {
        return {}
    },
    props: {
        itemData: {},
    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
        this.initSetData()
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        initSetData() {
            this.type = this.itemData.i_type;
            this.parentChange('type', this.type);
            this.category = this.itemData.ic_idx;
            this.parentChange('category', this.category);
            this.enroll = this.itemData.im_enroll;
            this.parentChange('enroll', this.enroll);
            // this.i_status = this.itemData.i_status;
            // this.parentChange('i_status', this.i_status);
            // this.status = this.itemData.im_status;
            // this.parentChange('status', this.status);\

            this.itemData.Hash.forEach(data => {
                let hash = {type: data.ih_type, tag: data.ih_hashtag}
                if (data.ih_type === 1) {
                    hash.tag = data.ic_idx;
                }
                this.hashtag.push(hash);
            })
            this.parentChange('hashtag', this.hashtag);
        },
        modalClose() {
            this.$modal.hide('premium-market-add-modal');
        },
        openModal(name) {
            this.$modal.show(name);
        },
        updateItem() {
            EventBus.$emit('updateItem');
        },
        parentChange(type, value) {
            this[type] = value;
            this.itemWriteSetData(type, value);
        },
    },

    watch: {},
}
</script>

<style scoped>

</style>